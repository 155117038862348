import React from "react";
import { connect } from "react-redux";
import { setvista, setflagintera } from "../../state/actions";

import { RiOpenArmLine, RiSurgicalMaskLine } from "react-icons/ri";

const NavViste = (props) => {
  const { vista, setVis, flag, setFlag, lingua } = props;

  return <div />;
};

function mapStateToProps(state) {
  return {
    flag: state.shop.flagIntera,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setVis: (val) => dispatch(setvista(val)),
    setFlag: (val) => dispatch(setflagintera(val)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(NavViste);
