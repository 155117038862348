import React from "react"
import { connect } from "react-redux"
import { settipomisure } from "../../state/actions"

import { Scrollbars } from "react-custom-scrollbars";

import MisuraSlider from "../misuraslider"
import MisuraDaTaglia from "../misuradataglia"
import MisuraInvia from "../misurainvia"

const Misure = props => {
  const { lingua, tipo, setTipoMisure } = props
  
  return (
    <div className="columns is-gapless has-background-white">
     
        <div className="column ">
          <div className="container-features">

            <Scrollbars autoHide style={{ height: "calc(100vh - 3.25rem)" }}>
              <div className="section" style={{ background: "#fff" }}>
                <nav className={tipo === 0 ? "panel is-primary" : "panel"} >
                  <button className="panel-heading" onClick={() => setTipoMisure(0)} style={tipo === 0 ? { pointerEvents:"none", width:"100%" } : { cursor:"pointer", textAlign:"left", width:"100%", border: "none" }}>
                    {lingua.navigazione.misure.metodoTaglia}
                  </button>
                  <div className={tipo === 0 ? "" : "is-hidden"} style={{ padding: "1.5rem" }}><MisuraDaTaglia lingua={lingua} /></div>
                </nav>
                <nav className={tipo === 1 ? "panel is-primary" : "panel"} >
                  <button className="panel-heading" onClick={() => setTipoMisure(1)} style={tipo === 1 ? { pointerEvents:"none", width:"100%" } : { cursor:"pointer", textAlign:"left", width:"100%", border: "none" }}>
                    {lingua.navigazione.misure.metodoCorpo}
                  </button>
                  <div className={tipo === 1 ? "" : "is-hidden"} style={{ padding: "1.5rem" }}><MisuraSlider lingua={lingua} tipo="corpo" /></div>
                </nav>
                <nav className={tipo === 2 ? "panel is-primary" : "panel"}>
                  <button className="panel-heading" onClick={() => setTipoMisure(2)} style={tipo === 2 ? { pointerEvents:"none", width:"100%" } : { cursor:"pointer", textAlign:"left", width:"100%", border: "none" }}>
                    {lingua.navigazione.misure.metodoCamicia}
                  </button>
                  <div className={tipo === 2 ? "" : "is-hidden"} style={{ padding: "1.5rem" }}><MisuraSlider lingua={lingua} tipo="camicia"/></div>
                </nav>
                <nav className={tipo === 3 ? "panel is-primary" : "panel"}>
                  <button className="panel-heading" onClick={() => setTipoMisure(3)} style={tipo === 3 ? { pointerEvents:"none", width:"100%" } : { cursor:"pointer", textAlign:"left", width:"100%", border: "none" }}>
                    {lingua.navigazione.misure.metodoInvia}
                  </button>
                  <div className={tipo === 3 ? "" : "is-hidden"} style={{ padding: "1.5rem" }}><MisuraInvia lingua={lingua} /></div>
                </nav>

              </div>

            </Scrollbars>
          </div>
        </div>
      </div>

   



  )
}

function mapStateToProps(state) {
  return {
    tipo: state.shop.camicia.misure.tipo,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    setTipoMisure: val => dispatch(settipomisure(val)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Misure)
