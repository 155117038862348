export const wrapTex = {
  "5557-101A": {
    repeat_x: 0.0052,
    repeat_y: 0.0082,
  },
  "5543-102A": {
    repeat_x: 0.0052,
    repeat_y: 0.0088,
  },
  "5602-102A": {
    repeat_x: 0.0052,
    repeat_y: 0.0035,
  },
  "5558-101A": {
    repeat_x: 0.0052,
    repeat_y: 0.0045,
  },
  "5574-101A": {
    repeat_x: 0.0052,
    repeat_y: 0.0052,
  },
  "5555-101B": {
    repeat_x: 0.0052,
    repeat_y: 0.0045,
  },
  "5550-101A": {
    repeat_x: 0.0052,
    repeat_y: 0.0052,
  },
  "5595-101B": {
    repeat_x: 0.0052,
    repeat_y: 0.0052,
  },
  "5682-101A": {
    repeat_x: 0.0052,
    repeat_y: 0.0052,
  },
  "5592-102A": {
    repeat_x: 0.0052,
    repeat_y: 0.0052,
  },
  "5718-240": {
    repeat_x: 0.0052,
    repeat_y: 0.0052,
  },
  "5677-101A": {
    repeat_x: 0.0052,
    repeat_y: 0.0035,
  },
  "5626-101B": {
    repeat_x: 0.0052,
    repeat_y: 0.0035,
  },
  "5006-101A": {
    repeat_x: 0.0052,
    repeat_y: 0.0062,
  },
  "4943-102A": {
    repeat_x: 0.0052,
    repeat_y: 0.0062,
  },
  "4845-101A": {
    repeat_x: 0.0052,
    repeat_y: 0.0052,
  },
  "4881-101A": {
    repeat_x: 0.0032,
    repeat_y: 0.0072,
  },
  "4942-101A": {
    repeat_x: 0.0052,
    repeat_y: 0.0052,
  },
  "4930-101A": {
    repeat_x: 0.0052,
    repeat_y: 0.0062,
  },
  "4943-101A": {
    repeat_x: 0.0052,
    repeat_y: 0.0062,
  },
  "4927-101A": {
    repeat_x: 0.0052,
    repeat_y: 0.0052,
  },
  "4942-102A": {
    repeat_x: 0.0052,
    repeat_y: 0.0052,
  },
  "4808-101A": {
    repeat_x: 0.0052,
    repeat_y: 0.0052,
  },
  "4972-101A": {
    repeat_x: 0.0052,
    repeat_y: 0.0062,
  },
  "3601-W1230-0021": {
    repeat_x: 0.032,
    repeat_y: 0.032,
  },
  "3091-W030-0387": {
    repeat_x: 0.0092,
    repeat_y: 0.0092,
  },
  "5882S-W24409-0002": {
    repeat_x: 0.042,
    repeat_y: 0.042,
  },
  "2420-W831-0008": {
    repeat_x: 0.022,
    repeat_y: 0.022,
  },
  "Zaffiro-16": {
    repeat_x: 0.032,
    repeat_y: 0.032,
  },
  "3601-W7360-0008": {
    repeat_x: 0.032,
    repeat_y: 0.032,
  },
  "2420-W021-0008": {
    repeat_x: 0.032,
    repeat_y: 0.032,
  },
  "3601-W8880-0002": {
    repeat_x: 0.032,
    repeat_y: 0.032,
  },
  "3601-W7360-0007": {
    repeat_x: 0.032,
    repeat_y: 0.032,
  },
  "3985H-W24275-0001": {
    repeat_x: 0.03,
    repeat_y: 0.03,
  },
  "3985H-W24271-0001": {
    repeat_x: 0.035,
    repeat_y: 0.035,
  },
  "2640-W20800-0011": {
    repeat_x: 0.18,
    repeat_y: 0.18,
  },
  "2420-W841-0271": {
    repeat_x: 0.075,
    repeat_y: 0.075,
  },
  "Savoy-99": {
    repeat_x: 0.032,
    repeat_y: 0.032,
  },
  "Duca-208": {
    repeat_x: 0.012,
    repeat_y: 0.012,
  },
  "3985-W1610-0015": {
    repeat_x: 0.0092,
    repeat_y: 0.0092,
  },
  "3985-W1610-0311": {
    repeat_x: 0.0092,
    repeat_y: 0.0092,
  },
  "3978-W010-0061": {
    repeat_x: 0.009,
    repeat_y: 0.009,
  },
  "2420-W021-0001": {
    repeat_x: 0.14,
    repeat_y: 0.14,
  },
  "2420-W021-0006": {
    repeat_x: 0.14,
    repeat_y: 0.14,
  },
  "2420-W021-0005": {
    repeat_x: 0.14,
    repeat_y: 0.14,
  },
  "2420-W841-0001": {
    repeat_x: 0.14,
    repeat_y: 0.14,
  },
  "2420-W831-0001": {
    repeat_x: 0.34,
    repeat_y: 0.34,
  },
  "2420-W831-0271": {
    repeat_x: 0.034,
    repeat_y: 0.034,
  },
  "2420-W841-0008": {
    repeat_x: 0.084,
    repeat_y: 0.084,
  },
  "3601-W1210-0021": {
    repeat_x: 0.24,
    repeat_y: 0.24,
  },
  "3601-W1210-0003": {
    repeat_x: 0.24,
    repeat_y: 0.24,
  },
  "3601-W8880-0009": {
    repeat_x: 0.64,
    repeat_y: 0.64,
  },
  "3601-W3600-0004": {
    repeat_x: 0.14,
    repeat_y: 0.14,
  },
  "3601-W1230-0041": {
    repeat_x: 0.54,
    repeat_y: 0.54,
  },
  "3601-W8880-0008": {
    repeat_x: 0.54,
    repeat_y: 0.54,
  },
  "3601-W7360-0003": {
    repeat_x: 0.14,
    repeat_y: 0.14,
  },
  "3090-W1074-0003": {
    repeat_x: 0.74,
    repeat_y: 0.74,
  },
  "3601-W3600-0007": {
    repeat_x: 0.14,
    repeat_y: 0.14,
  },
  "3601-W7360-0005": {
    repeat_x: 0.2,
    repeat_y: 0.2,
  },
  "3090-W11000-0001": {
    repeat_x: 0.44,
    repeat_y: 0.44,
  },
  "3601-W1230-0003": {
    repeat_x: 0.44,
    repeat_y: 0.44,
  },
  "3601-W7350-0005": {
    repeat_x: 1.14,
    repeat_y: 1.14,
  },
  "3601-W7350-0006": {
    repeat_x: 1.14,
    repeat_y: 1.14,
  },
  "3601-W1230-0031": {
    repeat_x: 0.44,
    repeat_y: 0.44,
  },
  "3601-W8880-0004": {
    repeat_x: 0.74,
    repeat_y: 0.74,
  },
  "3601-W7350-0008": {
    repeat_x: 1.44,
    repeat_y: 1.44,
  },
  "3601-W1230-0061": {
    repeat_x: 0.44,
    repeat_y: 0.44,
  },
  "3601-W7360-0004": {
    repeat_x: 0.14,
    repeat_y: 0.14,
  },
  "3601-W8880-0011": {
    repeat_x: 0.74,
    repeat_y: 0.74,
  },
  "3601-W7350-0004": {
    repeat_x: 1.14,
    repeat_y: 1.14,
  },
  "3601-W3600-0001": {
    repeat_x: 0.14,
    repeat_y: 1,
  },
  "3601-W3600-0003": {
    repeat_x: 0.14,
    repeat_y: 1,
  },
  "3601-W7350-0003": {
    repeat_x: 1.4,
    repeat_y: 1.4,
  },
  "4343-W20720-0004": {
    repeat_x: 0.3,
    repeat_y: 0.3,
  },
  "4343-W20720-0001": {
    repeat_x: 0.3,
    repeat_y: 0.3,
  },
  "6459-W13470-0003": {
    repeat_x: 0.14,
    repeat_y: 0.14,
  },
  "6459-W13470-0002": {
    repeat_x: 0.14,
    repeat_y: 0.14,
  },
  "6459-W13470-0001": {
    repeat_x: 0.14,
    repeat_y: 0.14,
  },
  "6459-W13440-0003": {
    repeat_x: 0.74,
    repeat_y: 0.74,
  },
  "6459-W13440-0002": {
    repeat_x: 0.74,
    repeat_y: 0.74,
  },
  "6459-W13440-0001": {
    repeat_x: 0.74,
    repeat_y: 0.74,
  },
  "4343-W20720-0002": {
    repeat_x: 0.28,
    repeat_y: 0.28,
  },
  "Zaffiro 14": {
    repeat_x: 0.2,
    repeat_y: 0.2,
  },
  "2420-W841-0271": {
    repeat_x: 0.18,
    repeat_y: 0.18,
  },
  "2420-W831-0008": {
    repeat_x: 0.38,
    repeat_y: 0.38,
  },
  "3985-W1610-0011": {
    repeat_x: 0.0092,
    repeat_y: 0.0092,
  },
  "3494-W010-0039": {
    repeat_x: 0.0092,
    repeat_y: 0.0092,
  },
  "3985-W1610-0101": {
    repeat_x: 0.0092,
    repeat_y: 0.0092,
  },
  "3494-W010-0011": {
    repeat_x: 0.0092,
    repeat_y: 0.0092,
  },
  "3985-W1610-0027": {
    repeat_x: 0.0092,
    repeat_y: 0.0092,
  },
  "3494-W010-0017": {
    repeat_x: 0.0092,
    repeat_y: 0.0092,
  },
  "3985-W1610-0282": {
    repeat_x: 0.0092,
    repeat_y: 0.0092,
  },
  "7906-W3930-0480": {
    repeat_x: 0.0092,
    repeat_y: 0.0092,
  },
  "3985-W1610-0051": {
    repeat_x: 0.0092,
    repeat_y: 0.0092,
  },
  "3978-W010-0031": {
    repeat_x: 0.0092,
    repeat_y: 0.0092,
  },
  "3985-W1610-0023": {
    repeat_x: 0.0092,
    repeat_y: 0.0092,
  },
  "3985-W1610-0285": {
    repeat_x: 0.0092,
    repeat_y: 0.0092,
  },
  "3985-W1610-0142": {
    repeat_x: 0.0092,
    repeat_y: 0.0092,
  },
  "3985-W1610-0017": {
    repeat_x: 0.0092,
    repeat_y: 0.0092,
  },
  "3985-W1610-0291": {
    repeat_x: 0.0092,
    repeat_y: 0.0092,
  },
  "3985-W1610-0014": {
    repeat_x: 0.0092,
    repeat_y: 0.0092,
  },
  "3095-W10870-0011": {
    repeat_x: 1.92,
    repeat_y: 1.92,
  },
  "3092-W040-0052": {
    repeat_x: 0.92,
    repeat_y: 0.92,
  },
  "3130-W050-0052": {
    repeat_x: 0.92,
    repeat_y: 0.92,
  },
  "3120-W020-0111": {
    repeat_x: 0.62,
    repeat_y: 0.62,
  },
  "3091-W030-1817": {
    repeat_x: 0.62,
    repeat_y: 0.62,
  },
  "3206-W010-0130": {
    repeat_x: 0.62,
    repeat_y: 0.62,
  },
  "3091-W030-1840": {
    repeat_x: 0.62,
    repeat_y: 0.62,
  },
  "3601-W7290-0003": {
    repeat_x: 0.62,
    repeat_y: 1,
  },
  "3120-W020-0114": {
    repeat_x: 0.92,
    repeat_y: 0.92,
  },
  "3120-W010-0151": {
    repeat_x: 1.92,
    repeat_y: 1.92,
  },
  "3601-W7290-0002": {
    repeat_x: 0.52,
    repeat_y: 1,
  },
  "3206-W010-0014": {
    repeat_x: 0.52,
    repeat_y: 1,
  },
  "3090-W020-2279": {
    repeat_x: 1.92,
    repeat_y: 1.92,
  },
  "3090-W020-2633": {
    repeat_x: 1.92,
    repeat_y: 1.92,
  },
  "3090-W020-0151": {
    repeat_x: 1.92,
    repeat_y: 1.92,
  },
  "3130-W050-0101": {
    repeat_x: 1.92,
    repeat_y: 1.92,
  },
  "3090-W020-0101": {
    repeat_x: 1.92,
    repeat_y: 1.92,
  },
  "3095-W10870-0101": {
    repeat_x: 0.92,
    repeat_y: 0.92,
  },
  "3091-W030-1830": {
    repeat_x: 0.82,
    repeat_y: 0.82,
  },
  "3090-W020-0221": {
    repeat_x: 0.82,
    repeat_y: 0.82,
  },
  "3130-W050-1227": {
    repeat_x: 0.82,
    repeat_y: 0.82,
  },
  "3092-W040-1817": {
    repeat_x: 0.82,
    repeat_y: 0.82,
  },
  "3120-W020-0012": {
    repeat_x: 0.82,
    repeat_y: 0.82,
  },
  "3206-W010-0101": {
    repeat_x: 0.82,
    repeat_y: 0.82,
  },
  "3091-W030-0151": {
    repeat_x: 0.82,
    repeat_y: 0.82,
  },
  "3091-W030-0101": {
    repeat_x: 0.82,
    repeat_y: 0.82,
  },
  "3120-W020-0024": {
    repeat_x: 0.82,
    repeat_y: 2,
  },
  "3601-W7290-0001": {
    repeat_x: 0.82,
    repeat_y: 0.82,
  },
  "3090-W020-2614": {
    repeat_x: 0.82,
    repeat_y: 0.82,
  },
  "3092-W040-0101": {
    repeat_x: 0.82,
    repeat_y: 0.82,
  },
  "3206-W010-0126": {
    repeat_x: 0.82,
    repeat_y: 0.82,
  },
  "3130-W050-1217": {
    repeat_x: 0.82,
    repeat_y: 0.82,
  },
  "3130-W050-0103": {
    repeat_x: 0.82,
    repeat_y: 0.82,
  },
  "3206-W010-0084": {
    repeat_x: 0.32,
    repeat_y: 0.32,
  },
  "3120-W010-0053": {
    repeat_x: 0.32,
    repeat_y: 0.32,
  },
  "3100-W012-0031": {
    repeat_x: 0.82,
    repeat_y: 0.82,
  },
  "3100-W012-0013": {
    repeat_x: 1.52,
    repeat_y: 1.52,
  },
  "3100-W012-0014": {
    repeat_x: 1.52,
    repeat_y: 1.52,
  },
  "3100-W012-0012": {
    repeat_x: 1.32,
    repeat_y: 1.32,
  },
  "6459-W5810-0016": {
    repeat_x: 0.7,
    repeat_y: 0.7,
  },
  "6459-W13450-0001": {
    repeat_x: 1.7,
    repeat_y: 1.7,
  },
  "4343-W20711-0001": {
    repeat_x: 1.2,
    repeat_y: 1.2,
  },
  "3091-W032-2010": {
    repeat_x: 1.2,
    repeat_y: 1.2,
  },
  "3091-W032-2011": {
    repeat_x: 1.2,
    repeat_y: 1.2,
  },
  "3097-W11001-0001": {
    repeat_x: 0.8,
    repeat_y: 0.8,
  },
  "3097-W1072-0003": {
    repeat_x: 1.2,
    repeat_y: 0.6,
  },
  "3340-W3380-0001": {
    repeat_x: 0.8,
    repeat_y: 0.8,
  },
  "4343-W20781-0001": {
    repeat_x: 1.2,
    repeat_y: 0.6,
  },
  "4343-W19641-0001": {
    repeat_x: 1.3,
    repeat_y: 1.3,
  },
  "4343-W20711-0002": {
    repeat_x: 1.2,
    repeat_y: 0.9,
  },
  "1623-002": {
    repeat_x: 0.14,
    repeat_y: 0.14,
  },
  "1623-403": {
    repeat_x: 0.14,
    repeat_y: 0.14,
  },
  "1554-849": {
    repeat_x: 0.2,
    repeat_y: 0.2,
  },
  "1621-488": {
    repeat_x: 0.2,
    repeat_y: 0.2,
  },
  "1623-035": {
    repeat_x: 0.2,
    repeat_y: 0.2,
  },
  "1623-665": {
    repeat_x: 0.2,
    repeat_y: 0.2,
  }, 
  "1623-488": {
    repeat_x: 0.2,
    repeat_y: 0.2,
  }, 
  "1554-002": {
    repeat_x: 0.32,
    repeat_y: 0.02,
  },
  "1554-035": {
    repeat_x: 0.2,
    repeat_y: 0.2,
  }, 
  "1554-403": {
    repeat_x: 0.32,
    repeat_y: 0.32,
  }, 
  "1554-665": {
    repeat_x: 0.32,
    repeat_y: 0.98,
  }, 
  "1554-488": {
    repeat_x: 0.32,
    repeat_y: 0.62,
  }, 
  "6065-W010-0101": {
    repeat_x: 0.2,
    repeat_y: 0.2,
  },
  "6065-W010-0001": {
    repeat_x: 0.03,
    repeat_y: 0.03,
  },
  "6065-W010-0006": {
    repeat_x: 0.03,
    repeat_y: 0.03,
  },
  "6065-W010-0009": {
    repeat_x: 0.03,
    repeat_y: 0.03,
  },
  "6065-W010-0005": {
    repeat_x: 0.052,
    repeat_y: 0.092,
  },
  "6065-W010-0014": {
    repeat_x: 0.032,
    repeat_y: 0.032,
  },
  "6065-W010-0004": {
    repeat_x: 0.032,
    repeat_y: 0.032,
  },
  "0260-W030-0101": {
    repeat_x: 0.2,
    repeat_y: 0.2,
  },
  "0260-W030-0028": {
    repeat_x: 0.92,
    repeat_y: 0.92,
  },
  "0260-W030-0114": {
    repeat_x: 0.92,
    repeat_y: 0.92,
  },
  "0260-W030-0111": {
    repeat_x: 0.92,
    repeat_y: 0.92,
  },
  "1285-W0022-0101": {
    repeat_x: 0.92,
    repeat_y: 0.92,
  },
  "1285-W0022-0003": {
    repeat_x: 0.52,
    repeat_y: 0.52,
  },
  "1288-W0030-0101": {
    repeat_x: 0.92,
    repeat_y: 0.92,
  },
  "1288-W0030-0003": {
    repeat_x: 0.92,
    repeat_y: 0.92,
  },
  "1279-W0250-0001": {
    repeat_x: 0.4,
    repeat_y: 0.4,
  },
  "1279-W0250-0002": {
    repeat_x: 0.4,
    repeat_y: 0.4,
  },
  "3206-W010-0127": {
    repeat_x: 1,
    repeat_y: 1,
  },
  "5906D-W010-0236": {
    repeat_x: 0.42,
    repeat_y: 0.42,
  },  
  "5906D-W010-0201": {
    repeat_x: 0.62,
    repeat_y: 0.002,
  },  
  "5906D-W010-0204": {
    repeat_x: 0.82,
    repeat_y: 0.002,
  },  
  "5906D-W010-0225": {
    repeat_x: 0.82,
    repeat_y: 0.002,
  },  
  "5906D-W010-0218": {
    repeat_x: 0.82,
    repeat_y: 0.002,
  }, 
  "5906D-W010-0234": {
    repeat_x: 0.82,
    repeat_y: 0.002,
  },   
  "5906D-W010-0206": {
    repeat_x: 0.82,
    repeat_y: 0.002,
  },   
  "2331-W3181-0001": {
    repeat_x: 0.092,
    repeat_y: 0.092,
  },
  "2331-W3191-0001": {
    repeat_x: 0.042,
    repeat_y: 0.042,
  },
  "2331-W3181-0002": {
    repeat_x: 0.092,
    repeat_y: 0.092,
  },
  "2331-W3191-0002": {
    repeat_x: 0.042,
    repeat_y: 0.042,
  },
  "2702-W0252-0471": {
    repeat_x: 0.92,
    repeat_y: 0.92,
  },
  "2702-W0252-0262": {
    repeat_x: 0.92,
    repeat_y: 0.92,
  },
  "2702-W0252-0482": {
    repeat_x: 0.52,
    repeat_y: 0.22,
  },
  "2702-W0252-0511": {
    repeat_x: 0.92,
    repeat_y: 0.92,
  },
  "2702-W0252-0501": {
    repeat_x: 0.92,
    repeat_y: 0.92,
  },
  "2702-W0252-0581": {
    repeat_x: 0.92,
    repeat_y: 0.92,
  },
  "2702-W0252-0551": {
    repeat_x: 0.92,
    repeat_y: 0.92,
  },
  "2702-W0252-0531": {
    repeat_x: 0.92,
    repeat_y: 0.92,
  },
  "2702-W0252-0075": {
    repeat_x: 0.92,
    repeat_y: 0.92,
  },
  "F69642-01": {
    repeat_x: 0.92,
    repeat_y: 0.92,
  },
  "F69642-1000": {
    repeat_x: 0.92,
    repeat_y: 0.92,
  },
  "P58509-1000": {
    repeat_x: 0.92,
    repeat_y: 0.92,
  },
  "P58509-50408": {
    repeat_x: 0.92,
    repeat_y: 0.92,
  },
  "P58509-50442": {
    repeat_x: 0.92,
    repeat_y: 0.92,
  },
  "F69644-01": {
    repeat_x: 0.92,
    repeat_y: 0.92,
  },
  "F69644-02": {
    repeat_x: 0.92,
    repeat_y: 0.92,
  },
  "F69644-03": {
    repeat_x: 0.92,
    repeat_y: 0.92,
  },
  "xxx": {
    repeat_x: 0.92,
    repeat_y: 0.92,
  },
  "P61729-01": {
    repeat_x: 0.92,
    repeat_y: 0.92,
  },
  "P61729-02": {
    repeat_x: 0.92,
    repeat_y: 0.92,
  },
  "F69651-01": {
    repeat_x: 0.3,
    repeat_y: 0.3,
  },
  "F69651-04": {
    repeat_x: 0.3,
    repeat_y: 0.3,
  },
  "F69651-06": {
    repeat_x: 0.3,
    repeat_y: 0.3,
  },
  "F69654-01": {
    repeat_x: 0.6,
    repeat_y: 0.6,
  },
  "F69654-04": {
    repeat_x: 0.6,
    repeat_y: 0.6,
  },
  "F69654-06": {
    repeat_x: 0.6,
    repeat_y: 0.6,
  },
  "xxx": {
    repeat_x: 0.92,
    repeat_y: 0.92,
  },
  "F69882-1000": {
    repeat_x: 0.92,
    repeat_y: 0.92,
  },
  "F69882-01": {
    repeat_x: 0.92,
    repeat_y: 0.92,
  },
  "P69544-01": {
    repeat_x: 0.92,
    repeat_y: 0.92,
  },
  "P69544-02": {
    repeat_x: 0.92,
    repeat_y: 0.92,
  },
  "P69544-03": {
    repeat_x: 0.92,
    repeat_y: 0.92,
  },
};

export const wrapCont = {
  "0": {
    repeat_x: 0.1,
    repeat_y: 0.1,
  },
  "1": {
    repeat_x: 0.025,
    repeat_y: 0.025,
  },
  "2": {
    repeat_x: 0.095,
    repeat_y: 0.095,
  },
  "3": {
    repeat_x: 0.065,
    repeat_y: 0.065,
  },
};
