import React, { useEffect, Suspense } from "react";

import { Canvas } from "react-three-fiber";

import {
  OrbitControls,
  PerspectiveCamera,
  Html,
  useTexture,
  useProgress,
} from "@react-three/drei";

// specifiche
import {wrapTex} from '../specifiche'

import Mascherina from "../../Model/Maske";

const Mas = (props) => {

  useEffect(() => {
    document.getElementById("canvas").style.cursor = `url('data:image/svg+xml;base64,${btoa(`<svg width="64" height="64" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill="rgba(255, 255, 255, 0.5)" d="M29.5 54C43.031 54 54 43.031 54 29.5S43.031 5 29.5 5 5 15.969 5 29.5 15.969 54 29.5 54z"/></svg>`)}'), auto`
  }, [])
  
  const {
    tessuto,
  } = props;

  function Content() {
    const [texture] = useTexture(["/tessuti/" + tessuto + ".jpg"]);

    texture.repeat = {
      x: wrapTex[tessuto].repeat_x,
      y: wrapTex[tessuto].repeat_y,
    };
    texture.wrapS = 1024;
    texture.wrapT = 1024;

    texture.flipY = false;

    let mask = <Mascherina tessuto={texture} />;

    return (
      <group rotation={[0.03, 0.4, 0]} >
        {mask}
      </group>
    );
  }

  return (
    <div className="container-viste" id="canvas">
      <Canvas invalidateFrameloop>
        <PerspectiveCamera makeDefault position={[0, 0, 1]} />
        <ambientLight intensity={0.6} />
        {/*<directionalLight position={[5, 10, 7.5]} intensity={0.7} />*/}
        <spotLight
          intensity={0.5}
          angle={0.9}
          position={[5, 25, 20]}
          color="white"
          penumbra={1}
        />
        <pointLight position={[-20, -5, -10]} color="white" intensity={0.3} />

        <Suspense fallback={<Loader />}>
          <Content />
        </Suspense>

        <OrbitControls
          enableDamping
          enableZoom
          minDistance={0.3}
          maxDistance={0.5}
          dampingFactor={0.5}
          minPolarAngle={Math.PI / 3.5}
          maxPolarAngle={Math.PI / 1.5}
        />
        
      </Canvas>
      
    </div>
  );
};

function Loader() {
  const { progress } = useProgress();
  return <Html center>{Math.floor(progress)}%</Html>;
}

export default Mas;
