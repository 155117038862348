import React, { Suspense, useEffect } from "react";

import { connect } from "react-redux";
import { setvista } from "../../../state/actions";

import { Canvas } from "react-three-fiber";

import {
  OrbitControls,
  PerspectiveCamera,
  useTexture,
  useMatcapTexture,
  useProgress,
  Html,
} from "@react-three/drei";

// specifiche
import { wrapTex, wrapCont } from "../specifiche";

// Colli
import Collo0 from "../../Model/Vela_collo_0";
import Collo1 from "../../Model/Vela_collo_1";
import Collo2 from "../../Model/Vela_collo_2";
import Collo3 from "../../Model/Vela_collo_3";
import Collo4 from "../../Model/Vela_collo_4";
import Collo5 from "../../Model/Vela_collo_5";
import Stand from "../../Model/Stand_collo";

// Polsini
import Polsino0 from "../../Model/Polsino_0";
import Polsino1 from "../../Model/Polsino_1";
import Polsino2 from "../../Model/Polsino_2";
import Polsino3 from "../../Model/Polsino_3";

// Contrasti
import ContrastoCollo from "../../Model/Contrasto_collo";
import ContrastoPolsino0 from "../../Model/Contrasto_polsino_0";
import ContrastoPolsino1 from "../../Model/Contrasto_polsino_1";
import ContrastoPolsino2 from "../../Model/Contrasto_polsino_2";
import ContrastoPolsino3 from "../../Model/Contrasto_polsino_3";
import ContrastoChiusura0 from "../../Model/Contrasto_chiusura";

// Maniche
import Manica0 from "../../Model/Manica_0";
import ManicaCorta0 from "../../Model/Manica_corta_0";

// Taschini
import Taschino1 from "../../Model/Taschino_1";
import Taschino2 from "../../Model/Taschino_2";
import Taschino3 from "../../Model/Taschino_3";
import Taschino4 from "../../Model/Taschino_4";

// fronte
import Fronte from "../../Model/Fronte";

// dietro
import Dietro0 from "../../Model/Dietro_0";
import Dietro1 from "../../Model/Dietro_1";
import Dietro2 from "../../Model/Dietro_2";
import Dietro3 from "../../Model/Dietro_3";

// placket
import Placket from "../../Model/Placket";

// iniziali
import Monogram from "../../Model/Monogram";

// etichetta
import Label from "../../Model/Etichetta";

const Tred = (props) => {
  useEffect(() => {
    document.getElementById(
      "canvas"
    ).style.cursor = `url('data:image/svg+xml;base64,${btoa(
      `<svg width="64" height="64" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill="rgba(255, 255, 255, 0.5)" d="M29.5 54C43.031 54 54 43.031 54 29.5S43.031 5 29.5 5 5 15.969 5 29.5 15.969 54 29.5 54z"/></svg>`
    )}'), auto`;
  }, []);

  const {
    tessuto,
    colletto,
    polsino,
    chiusura,
    club,
    taschino,
    pinces,
    contrasto,
    contrastoColletto,
    contrastoPolsino,
    contrastoChiusura,
    asola,
    bottone,
    iniziali,
  } = props;

  function Content() {
    let carica_contrasto =
      contrasto > 0 ? `/contrasti/${contrasto}.jpg` : `/contrasti/0.png`;

    const [texture2] = useMatcapTexture(("texture index", 482), 1024); //546

    const [texture, texture1, eti] = useTexture([
      "/tessuti/" + tessuto + ".jpg",
      carica_contrasto,
      "/etichetta_3dshirt.jpg",
    ]);

    texture.repeat = {
      x: wrapTex[tessuto].repeat_x,
      y: wrapTex[tessuto].repeat_y,
    };
    texture.wrapS = 1024;
    texture.wrapT = 1024;

    texture.flipY = false;

    texture1.repeat = {
      x: wrapCont[contrasto].repeat_x,
      y: wrapCont[contrasto].repeat_y,
    };
    texture1.wrapS = 1024;
    texture1.wrapT = 1024;

    texture.flipY = false;

    eti.repeat = {
      x: 0.02,
      y: 0.06,
    };
    eti.wrapS = 140;
    eti.wrapT = 140;
    eti.flipY = true;

    //lucido
    let lucido = 1;
    if (
      tessuto === "1623-403" ||
      tessuto === "1623-002" ||
      tessuto === "1554-849" ||
      tessuto === "1621-488" ||
      tessuto === "1623-035"
    ) {
      lucido = 0.5;
    } else {
      lucido = 1;
    }

    // colore bottoni
    let coloreBottone = "#f5f5f5";
    switch (bottone) {
      case 0:
        coloreBottone = "#f5f5f5";
        break;
      case 1:
        coloreBottone = "#3273dc";
        break;
      case 2:
        coloreBottone = "#3298dc";
        break;
      case 3:
        coloreBottone = "#7a7a7a";
        break;
      case 4:
        coloreBottone = "#121212";
        break;
      case 5:
        coloreBottone = "#f14668";
        break;
      case 6:
        coloreBottone = "#ff8c00";
        break;
      case 7:
        coloreBottone = "#008000";
        break;
      case 8:
        coloreBottone = "#db7093";
        break;
      default:
        coloreBottone = "#f5f5f5";
    }

    // colore asole
    let coloreAsola = "#f5f5f5";
    let opaAsola = 0.5;
    switch (asola) {
      case -1:
        coloreAsola = "#f5f5f5";
        opaAsola = 0.5;
        break;
      case 0:
        coloreAsola = "#f5f5f5";
        opaAsola = 1;
        break;
      case 1:
        coloreAsola = "#3273dc";
        opaAsola = 1;
        break;
      case 2:
        coloreAsola = "#3298dc";
        opaAsola = 1;
        break;
      case 3:
        coloreAsola = "#7a7a7a";
        opaAsola = 1;
        break;
      case 4:
        coloreAsola = "#121212";
        opaAsola = 1;
        break;
      case 5:
        coloreAsola = "#f14668";
        opaAsola = 1;
        break;
      case 6:
        coloreAsola = "#ff8c00";
        opaAsola = 1;
        break;
      case 7:
        coloreAsola = "#008000";
        opaAsola = 1;
        break;
      case 8:
        coloreAsola = "#db7093";
        opaAsola = 1;
        break;
      default:
        coloreAsola = "#f5f5f5";
        opaAsola = 0.5;
    }

    //colletti
    let collo = <Collo0 tessuto={texture} club={club} />;
    switch (colletto) {
      case 0:
        collo = <Collo0 tessuto={texture} club={club} />;
        break;
      case 1:
        collo = <Collo1 tessuto={texture} club={club} />;
        break;
      case 2:
        collo = <Collo2 tessuto={texture} club={club} />;
        break;
      case 3:
        collo = <Collo3 tessuto={texture} club={club} />;
        break;
      case 4:
        collo = (
          <Collo4
            tessuto={texture}
            bottoni={coloreBottone}
            asole={coloreAsola}
            opa={opaAsola}
            matBottone={texture2}
            club={club}
          />
        );
        break;
      case 5:
        collo = <Collo5 tessuto={texture} club={club} />;
        break;
      default:
        collo = <Collo0 tessuto={texture} club={club} />;
    }

    let stand = (
      <Stand
        tessuto={texture}
        bottoni={coloreBottone}
        asole={coloreAsola}
        opa={opaAsola}
        matBottone={texture2}
        club={club}
      />
    );

    let contrCollo = <ContrastoCollo tessuto={texture1} />;
    let contrChiusura = <ContrastoChiusura0 tessuto={texture1} />;

    // polsini
    let contrPolso = <ContrastoPolsino0 tessuto={texture1} />;
    let polso = (
      <Polsino0
        tessuto={texture}
        bottoni={coloreBottone}
        asole={coloreAsola}
        opa={opaAsola}
        matBottone={texture2}
        club={club}
      />
    );
    switch (polsino) {
      case 0:
        polso = (
          <Polsino0
            tessuto={texture}
            bottoni={coloreBottone}
            asole={coloreAsola}
            opa={opaAsola}
            matBottone={texture2}
            club={club}
          />
        );
        contrPolso = <ContrastoPolsino0 tessuto={texture1} />;
        break;
      case 1:
        polso = (
          <Polsino1
            tessuto={texture}
            bottoni={coloreBottone}
            asole={coloreAsola}
            opa={opaAsola}
            matBottone={texture2}
            club={club}
          />
        );
        contrPolso = <ContrastoPolsino1 tessuto={texture1} />;
        break;
      case 2:
        polso = (
          <Polsino2
            tessuto={texture}
            bottoni={coloreBottone}
            asole={coloreAsola}
            opa={opaAsola}
            matBottone={texture2}
            club={club}
          />
        );
        contrPolso = <ContrastoPolsino2 tessuto={texture1} />;
        break;
      case 3:
        polso = (
          <Polsino3 tessuto={texture} club={club} matBottone={texture2} />
        );
        contrPolso = <ContrastoPolsino3 tessuto={texture1} />;
        break;
      case 4:
        polso = <ManicaCorta0 tessuto={texture} />;
        contrPolso = null;
        break;
      default:
        polso = (
          <Polsino0
            tessuto={texture}
            bottoni={coloreBottone}
            asole={coloreAsola}
            opa={opaAsola}
            matBottone={texture2}
            club={club}
          />
        );
        contrPolso = <ContrastoPolsino0 tessuto={texture1} />;
    }

    // taschini
    let tasca = null;
    switch (taschino) {
      case 0:
        tasca = null;
        break;
      case 1:
        tasca = <Taschino1 tessuto={texture} />;
        break;
      case 2:
        tasca = <Taschino2 tessuto={texture} />;
        break;
      case 3:
        tasca = <Taschino3 tessuto={texture} />;
        break;
      case 4:
        tasca = <Taschino4 tessuto={texture} />;
        break;
      default:
        tasca = null;
    }

    // fronte
    let fronte = <Fronte tessuto={texture} lucido={lucido} />;

    // dietro
    let dietro = <Dietro0 tessuto={texture} />;

    switch (pinces) {
      case 0:
        dietro = <Dietro0 tessuto={texture} />;
        break;
      case 1:
        dietro = <Dietro1 tessuto={texture} />;
        break;
      case 2:
        dietro = <Dietro2 tessuto={texture} />;
        break;
      case 3:
        dietro = <Dietro3 tessuto={texture} />;
        break;
      default:
        dietro = <Dietro0 tessuto={texture} />;
    }

    let placca = (
      <Placket
        tessuto={texture}
        bottoni={coloreBottone}
        asole={coloreAsola}
        opa={opaAsola}
        matBottone={texture2}
        chiusura={chiusura}
      />
    );
    let manica = <Manica0 tessuto={texture} lucido={lucido} />;

    let monog = <Monogram iniziali={iniziali} />;

    let etichetta = <Label label={eti} />;

    return (
      <group rotation={[0, 0, 0]}>
        {colletto === 6 ? null : collo}
        {contrasto > 0 && contrastoColletto ? contrCollo : null}
        {stand}
        {contrasto > 0 && contrastoChiusura ? contrChiusura : null}
        {fronte}
        {placca}
        {dietro}
        {polsino === 4 ? null : manica}
        {contrasto > 0 && contrastoPolsino ? contrPolso : null}
        {polso}
        {tasca}
        {monog}
        {etichetta}
      </group>
    );
  }

  return (
    <div id="canvas" className="container-viste">
      <Canvas invalidateFrameloop>
        <PerspectiveCamera
          makeDefault
          position={[Math.PI / -0.2, Math.PI / 0.8, Math.PI / 0.2]}
        />
        <ambientLight intensity={0.5} />
        <directionalLight position={[5, 10, 7.5]} intensity={0.3} />
        <spotLight
          intensity={0.5}
          angle={0.9}
          position={[5, 25, 20]}
          color="white"
          penumbra={1}
        />

        <pointLight position={[-20, -5, -10]} color="white" intensity={0.3} />

        <Suspense fallback={<Loader />}>
          <Content />
        </Suspense>

        <OrbitControls
          enableDamping
          enableZoom
          minDistance={0.5}
          maxDistance={1}
          dampingFactor={0.5}
          minPolarAngle={Math.PI / 3.5}
          maxPolarAngle={Math.PI / 1.5}
        />
      </Canvas>
    </div>
  );
};

function Loader() {
  const { progress } = useProgress();
  return <Html center>{Math.floor(progress)}%</Html>;
}

function mapStateToProps(state) {
  return {
    colletto: state.shop.camicia.colletto,
    polsino: state.shop.camicia.polsino,
    club: state.shop.camicia.club,
    chiusura: state.shop.camicia.chiusura,
    taschino: state.shop.camicia.taschino,
    pinces: state.shop.camicia.pince,
    contrasto: state.shop.camicia.contrasto,
    contrastoColletto: state.shop.camicia.contrastoColletto,
    contrastoPolsino: state.shop.camicia.contrastoPolsino,
    contrastoChiusura: state.shop.camicia.contrastoChiusura,
    asola: state.shop.camicia.asola,
    bottone: state.shop.camicia.bottone,
    iniziali: state.shop.camicia.iniziali,
    elastico: state.shop.camicia.elastico,
    ruota: state.shop.rotazione,
    cameraOrbit: state.shop.cameraOrbit,
    fieldOfView: state.shop.fieldOfView,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setVista: (val) => dispatch(setvista(val)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Tred);
